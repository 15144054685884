<template>
  <div
    :style="`background: ${$theme.background};`"
    class="expande-horizontal wrap pa-3"
  >
    <v-flex v-if="$route.path === '/transacoes'" xs12 class="pr-2 pb-1" md3>
      <v-select
        hide-details
        label="Selecione a forma de pagamento"
        placeholder="ex: Dinheiro"
        v-model="get_transacoes_filtros.forma_de_pagamento"
        :items="['DINHEIRO', 'CRÉDITO', 'DÉBITO', 'FIADO']"
        @input="debounceSearch"
        dense
        solo
        background-color="#f2f2f2"
        flat
      ></v-select>
    </v-flex>
    <!-- <v-flex xs12 md3 class="pr-2">
      <v-text-field
        dense
        solo
        background-color="#f2f2f2"
        small
        flat
        label="Filtrar por nome"
        v-model="get_transacoes_filtros.nome"
        clearable
        hide-details
        :color="$theme.primary"
        @input="debounceSearch"
      ></v-text-field>
    </v-flex> -->

    <!-- <v-flex xs12 md3 class="pr-2 pb-1">
            <v-autocomplete
                dense
                solo-inverted
                small
                flat
                item-text="nome"
                label="Filtrar por Setor"
                :items="get_setores.docs"
                v-model="get_transacoes_filtros.setor"
                return-object
                clearable
                hide-details
                @input="debounceSearch"
            ></v-autocomplete>
        </v-flex> -->

    <v-flex v-if="$route.path === '/transacoes'" xs12 md2 class="pr-2 pb-1">
      <v-autocomplete
        dense
        solo-inverted
        flat
        label="Filtrar por cliente"
        clearable
        v-model="get_transacoes_filtros.cliente"
        @input="debounceSearch"
        item-text="nome"
        return-object
        hide-details
        background-color="#f2f2f2"
        :items="get_clientes.docs"
      ></v-autocomplete>
    </v-flex>

    <v-flex
      v-if="$route.name === 'Próximos Vencimentos'"
      xs12
      md2
      class="pr-2 pb-1"
    >
      <v-select
        dense
        solo-inverted
        flat
        label="Vencimento daqui à"
        clearable
        v-model="get_transacoes_filtros.vencimento_daqui_a"
        @input="debounceSearch"
        hide-details
        background-color="#f2f2f2"
        :items="[5, 10, 15, 20, 30]"
      ></v-select>
    </v-flex>

    <v-flex v-if="$route.path === '/transacoes'" xs12 md2 class="pr-2 pb-1">
      <v-select
        dense
        solo
        flat
        label="Filtrar por status"
        clearable
        v-model="get_transacoes_filtros.status_atual"
        @input="debounceSearch"
        hide-details
        background-color="#f2f2f2"
        :items="['pago', 'agendado', 'atrasado']"
      ></v-select>
    </v-flex>

    <v-flex v-if="$route.path === '/transacoes'" xs12 md2 class="pb-1 pr-2">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            solo
            flat
            background-color="#f2f2f2"
            hide-details=""
            v-model="computedInitialDate"
            color="grey darken-1"
            label="Data Inicial"
            @blur="
              get_transacoes_filtros.dataInicio = $helper.formatDate(
                get_transacoes_filtros.dataInicio
              )
            "
            readonly
            clearable
            @input="listar_transacoes"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          dense
          solo
          small
          flat
          label="Data Inicial"
          clearable
          lang="pt-br"
          background-color="#f2f2f2"
          type="date"
          v-model="get_transacoes_filtros.dataInicio"
          @input="listar_transacoes"
          hide-details
        >
        </v-date-picker>
      </v-menu>
    </v-flex>

    <v-flex v-if="$route.path === '/transacoes'" xs12 md3 class="pb-1 pr-1">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            solo
            flat
            hide-details=""
            v-model="computedFinalDate"
            chips
            color="grey darken-1"
            small-chips
            background-color="#f2f2f2"
            label="Data final"
            @blur="
              get_transacoes_filtros.dataFim = $helper.formatDate(
                get_transacoes_filtros.dataFim
              )
            "
            readonly
            clearable
            @input="listar_transacoes"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          label="Data final"
          lang="pt-br"
          type="date"
          v-model="get_transacoes_filtros.dataFim"
          @input="listar_transacoes"
        >
        </v-date-picker>
      </v-menu>
    </v-flex>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
var _ = require("lodash");
export default {
  data() {
    return {
      menu: false,
      menu1: false,
      menu2: false,
      dates1: "",
      dates2: ""
    };
  },
  computed: {
    ...mapGetters([
      "get_transacoes",
      "get_setores",
      "get_clientes",
      "get_transacoes_filtros",
      "get_clientes_filtros"
    ]),
    computedInitialDate: {
      get() {
        const data = this.$moment(
          this.get_transacoes_filtros.dataInicio
        ).format("DD/MM/YYYY");
        if (data === "Data inválida") {
          return "";
        } else {
          return data;
        }
      },
      set(value) {
        this.get_transacoes_filtros.dataInicio = value;
      }
    },
    computedFinalDate: {
      get() {
        const data = this.$moment(this.get_transacoes_filtros.dataFim).format(
          "DD/MM/YYYY"
        );
        if (data === "Data inválida") {
          return "";
        } else {
          return data;
        }
      },
      set(value) {
        this.get_transacoes_filtros.dataFim = value;
      }
    }
  },
  methods: {
    ...mapActions(["listar_transacoes"]),
    debounceSearch() {
      const x = _.debounce(this.listar_transacoes, 800);
      x();
    }
  }
};
</script>
