var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","persistent":"","transition":"slide-x-transition"},model:{value:(_vm.get_modal_view_transacao),callback:function ($$v) {_vm.get_modal_view_transacao=$$v},expression:"get_modal_view_transacao"}},[_c('v-card',{staticClass:"expande-horizontal pa-12 wrap",attrs:{"dark":""}},[_c('div',{staticClass:"expande-horizontal px-1 pr-3 py-3"},[_c('img',{attrs:{"width":"60","src":"https://s3-alpha-sig.figma.com/img/3f2b/ecfe/f8eb98f7612621c45534a55e1a82ee4a?Expires=1637539200&Signature=PBCIgMgjpTGCYiSKP0x319Fx75YqBSPJJFt9R-UWVQlBfJRvkFxK5HoyQ-1W1ahQV-ngtPSBW3WJkC6ERWNEhMD1zXg0RvRjFa77dnnQMqtW4HEGsoOqpeBnIUiRpbM5oo~2HuSsoyAFcNyS8XJuannzg3SXkGOKYJPIHYXEK1Q4Xmj0VAVkzdlIUaqV1uFS6Fvfz-WBQveyIZ7Ly9eRbQXYY~yPbtmn9DzmKsz0elvadDKTaM0w0q7nvTC0LS2MCOCz7C0fbBFfVXbFGvk3yKSVgLs55vlyPPfq0MYUC4iEySPJK6lA-r9LGiBNpOKbVJax~SJIqJTRN39kI~BKJw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA","alt":""}}),_c('v-spacer'),_c('h2',{staticStyle:{"color":"#f2f2f2"}},[_vm._v("CONFIRMAR PAGAMENTO")]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-3",attrs:{"icon":""},on:{"click":_vm.fecha_modal_view_transacao}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticClass:"expande-horizontal column centraliza"},[_c('h1',{style:(`font-size: 30pt; color: #558870`)},[_vm._v(" R$ "+_vm._s(_vm.get_transacao.valor)+" ")]),_c('h2',{staticClass:"py-6",staticStyle:{"color":"#999"}},[_vm._v(" Anexe um comprovante de pagamento ")]),_c('modal-send-archive')],1),_c('div',{staticClass:"expande-horizontal"},[_c('v-select',{attrs:{"label":"Selecione a forma de pagamento","placeholder":"ex: Dinheiro","items":[
          'DINHEIRO',
          'PIX',
          'CARTÃO DE CRÉDITO',
          'CARTÃO DE DÉBITO',
          'BOLETO'
        ]},model:{value:(_vm.get_transacao.forma_de_pagamento),callback:function ($$v) {_vm.$set(_vm.get_transacao, "forma_de_pagamento", $$v)},expression:"get_transacao.forma_de_pagamento"}})],1),_c('div',{staticClass:"expande-horizontal centraliza pa-3"},[_c('v-btn',{attrs:{"color":"#558870","dark":"","block":""},on:{"click":_vm.atualizar_transacao}},[_c('span',[_vm._v("Confirmar Pagamento")]),_c('v-icon',[_vm._v("mdi-check")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }