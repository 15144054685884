<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_transacao"
  >
    <v-card dark class="expande-horizontal pa-12 wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <img
          width="60"
          src="https://s3-alpha-sig.figma.com/img/3f2b/ecfe/f8eb98f7612621c45534a55e1a82ee4a?Expires=1637539200&Signature=PBCIgMgjpTGCYiSKP0x319Fx75YqBSPJJFt9R-UWVQlBfJRvkFxK5HoyQ-1W1ahQV-ngtPSBW3WJkC6ERWNEhMD1zXg0RvRjFa77dnnQMqtW4HEGsoOqpeBnIUiRpbM5oo~2HuSsoyAFcNyS8XJuannzg3SXkGOKYJPIHYXEK1Q4Xmj0VAVkzdlIUaqV1uFS6Fvfz-WBQveyIZ7Ly9eRbQXYY~yPbtmn9DzmKsz0elvadDKTaM0w0q7nvTC0LS2MCOCz7C0fbBFfVXbFGvk3yKSVgLs55vlyPPfq0MYUC4iEySPJK6lA-r9LGiBNpOKbVJax~SJIqJTRN39kI~BKJw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          alt=""
        />
        <v-spacer></v-spacer>
        <h2 style="color: #f2f2f2">CONFIRMAR PAGAMENTO</h2>
        <v-spacer></v-spacer>

        <v-btn class="elevation-3" icon @click="fecha_modal_view_transacao">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="expande-horizontal column centraliza">
        <h1 :style="`font-size: 30pt; color: #558870`">
          R$ {{ get_transacao.valor }}
        </h1>
        <h2 class="py-6" style="color: #999">
          Anexe um comprovante de pagamento
        </h2>
        <modal-send-archive />
      </div>

      <div class="expande-horizontal">
        <v-select
          label="Selecione a forma de pagamento"
          placeholder="ex: Dinheiro"
          v-model="get_transacao.forma_de_pagamento"
          :items="[
            'DINHEIRO',
            'PIX',
            'CARTÃO DE CRÉDITO',
            'CARTÃO DE DÉBITO',
            'BOLETO'
          ]"
        ></v-select>
      </div>
      <div class="expande-horizontal centraliza pa-3">
        <v-btn @click="atualizar_transacao" color="#558870" dark block>
          <span>Confirmar Pagamento</span>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchiveFile.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_transacao",
      "get_transacaos",
      "get_modal_view_transacao",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_transacao",
      "atualizar_transacao",
      "fecha_modal_view_transacao"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_transacao.status = "pago";
        this.get_transacao._id
          ? this.atualizar_transacaos()
          : this.criar_transacaos();
      }
    }
  }
};
</script>
