<template>
  <v-flex class="pa-3" :style="`background: ${$theme.background};`" xs12>
    <v-simple-table
      :style="
        `background: ${$theme.background}; min-height: 500px; border-radius: 6px; border: 1px solid #F2F2F2`
      "
    >
      <template v-slot:default>
        <thead>
          <tr>
            <!-- <td class="font-weight-bold"> </td> -->
            <td class="fonte font-weight-bold">COMPETÊNCIA</td>
            <td class="fonte font-weight-bold">VALOR</td>
            <td class="fonte font-weight-bold">CLIENTE</td>
            <td class="fonte font-weight-bold">STATUS</td>
            <td class="fonte font-weight-bold">FORMA DE PAGAMENTO</td>
          </tr>
        </thead>
        <tbody>
          <tr
            class="fonte"
            :style="
              `cursor: pointer; background: ${i % 2 === 0 ? '#f2f2f2' : '#fff'}`
            "
            v-for="(item, i) in get_transacoes.docs"
            :key="item.id"
          >
            <td class="fonte">
              {{ $moment(item.created_at).format("DD/MM/YYYY") }}
            </td>
            <td
              class="green--text fonte font-weight-bold"
              @click="abre_modal_view_transacaos(item)"
            >
              R$ {{ item.compra ? item.compra.preco_total : 0 }}
            </td>
            <td class="fonte" @click="abre_modal_view_transacaos(item)">
              {{
                item.compra.cliente ? item.compra.cliente.nome : "SEM CLIENTE"
              }}
            </td>
            <td
              @click="abre_modal_view_transacaos(item)"
              class="fonte font-weight-bold"
              :class="
                item.status_atual === 'pago' ? 'green--text' : 'red--text'
              "
            >
              {{ item.status_atual.status }}
            </td>
            <td
              @click="abre_modal_view_transacaos(item)"
              class="fonte font-weight-bold"
            >
              <span v-if="item.compra.debito">Débito </span>
              <span v-if="item.compra.credito">Crédito </span>
              <span v-if="item.compra.fiado">Fiado </span>
              <span v-if="item.compra.dinheiro">Dinheiro</span>
            </td>
            <!-- <td style="width: 10px">
              <v-btn
                @click="
                  createConfirmAction({
                    message: 'Deseja remover este item?',
                    icon: 'mdi-close-circle',
                    action: 'excluir_transacaos',
                    action_value: item
                  })
                "
                icon
                ><v-icon> mdi-delete </v-icon></v-btn
              >
            </td> -->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ModalView />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalView from "../components/modalView.vue";
export default {
  data() {
    return {
      modal_escolha_template: false,
      item: null,
      template_escolhido: null,
      opcoes_possiveis: [
        {
          descricao: "Quebra de Linha",
          chave: "%0A"
        },
        {
          descricao: "Nome do cliente",
          chave: "item.cliente.nome"
        },
        {
          descricao: "Primeiro nome apenas",
          chave: "item.cliente.nome.split(' ')[0]"
        },
        {
          descricao: "Data do vencimento da fatura",
          chave: "this.$moment(item.data_do_vencimento).format('DD/MM/YYYY')"
        }
      ]
    };
  },
  props: ["transacoes"],
  computed: {
    ...mapGetters(["get_transacoes"])
  },
  components: { ModalView },
  methods: {
    ...mapActions([
      "abre_modal_view_transacaos",
      "createConfirmAction",
      "createGlobalMessage"
    ]),
    chamarNoWhats(template) {
      if (this.item.cliente.whatsapp) {
        let item = this.item;
        let numero = item.cliente.whatsapp
          .split("")
          .filter(letra => letra !== " ");
        numero = numero.filter(letra => letra !== "(");
        numero = numero.filter(letra => letra !== ")");
        numero = numero.filter(letra => letra !== "-");
        numero = numero.join("");
        // numero = "96984196827";
        let mensagem = template.mensagem;
        let msg = mensagem.split(" ");
        let new_msg = "";

        msg.map(palavra => {
          if (palavra === "item.cliente.nome,") {
            new_msg = `${new_msg} ${item.cliente.nome},`;
          } else if (
            palavra ===
            "this.$moment(item.data_do_vencimento).format('DD/MM/YYYY'),"
          ) {
            new_msg = `${new_msg} ${this.$moment(
              item.data_do_vencimento
            ).format("DD/MM/YYYY")},`;
          } else if (palavra === "item.cliente.nome.split(' ')[0],") {
            new_msg = `${new_msg} ${item.cliente.nome.split(" ")[0]},`;
          } else if (palavra === "item.cliente.nome") {
            new_msg = `${new_msg} ${item.cliente.nome}`;
          } else if (
            palavra ===
            "this.$moment(item.data_do_vencimento).format('DD/MM/YYYY')"
          ) {
            new_msg = `${new_msg} ${this.$moment(
              item.data_do_vencimento
            ).format("DD/MM/YYYY")}`;
          } else if (palavra === "item.cliente.nome.split(' ')[0]") {
            new_msg = `${new_msg} ${item.cliente.nome.split(" ")[0]}`;
          } else {
            new_msg = `${new_msg} ${palavra}`;
          }
        });

        window.open(`https://wa.me/55${numero}/?text=${new_msg}`, "_blank");
        this.item = null;
        this.modal_escolha_template = false;
      } else {
        this.createGlobalMessage({
          type: "warning",
          timeout: 4000,
          message: "O cliente não tem whatsapp cadastrado."
        });
      }
    }
  }
};
</script>
